const split = (str, tokens) => {
  let tempChar = tokens[0]; // We can use the first token as a temporary join character
  for (let i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
};

export const slugify = (str) => {
  const slug = split(str, [" ", "_", "--", "-", /[^a-z0-9-]/g])
    .map((e) => e.toLowerCase())
    .join("-");
  return slug;
};
