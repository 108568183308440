import { useEffect, useState } from "react";
import {
  Modal,
  InputGroup,
  FormLabel,
  Form,
  Button,
  FormSelect,
} from "react-bootstrap";
import axios from "axios";
// import Modal from "../shared/Modal";
import { loginUrl, host } from "../../variables";
import { generateName, slugify } from "../../utils";

import ChatGroupModal from "./ChatGroupModal";
import Loading from "../../shared/Loading";
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const hoursMs = 1000 * 60 * 60;
export default function () {
  const [loginForm, setLoginForm] = useState({ username: "", password: "" });
  const [showLoginModal, setShowLoginModal] = useState(true);
  // console.log({ loginForm });
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const [allChatGroups, setAllChatGroups] = useState([]);
  const [selectedChatGroup, setSelectedChatGroup] = useState(null);

  const [messagesDataByChatGroup, setMessagesDataByChatGroup] = useState({});
  // const [messagesData, setMessagesData] = useState([]);

  const messagesData = messagesDataByChatGroup[selectedChatGroup?._id]
    ? [...messagesDataByChatGroup[selectedChatGroup?._id]]
    : [];
  console.log({ messagesData, messagesDataByChatGroup });
  // const messagesData = [];

  const [lastFetchedMessages, setLastFetchedMessages] = useState([]);

  const [messageInput, setMessageInput] = useState("");
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [getMessageLoading, setGetMessageLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const [scrolledToBottom, setScrolledToBottom] = useState(true);

  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState(false);
  // console.log({ scrolledToBottom });

  const [showCreateChatGroupModal, setShowCreateChatGroupModal] =
    useState(false);

  const [showUpdateChatGroupModal, setShowUpdateChatGroupModal] =
    useState(false);

  const [getMessagesPromise, setGetMessagesPromise] = useState(() => {});

  const scrollToBottom = () => {
    let chatContainerElement = document.getElementById("chatItemContainer");
    const scrollHeight = chatContainerElement?.scrollHeight;
    chatContainerElement?.scrollTo({
      top: scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  const checkIfScrolledToBottom = () => {
    // console.log("checkIfScrolledToBottom");
    let chatContainerElement = document.getElementById("chatItemContainer");

    const scrollTop = chatContainerElement?.scrollTop;
    const scrollHeight = chatContainerElement?.scrollHeight;
    const offsetHeight = chatContainerElement?.offsetHeight;

    if (scrollHeight == offsetHeight) {
      setScrolledToBottom(true);
      return;
    }

    const allChatsCounts = chatContainerElement?.childElementCount;
    const newMessagesCount = lastFetchedMessages.length;

    const newMessageChatNodes = [];
    chatContainerElement?.childNodes?.forEach((e, i) => {
      if (i > allChatsCounts - 1 - newMessagesCount) {
        newMessageChatNodes.push(e);
      }
    });

    var totalOffsetHeight = Number(offsetHeight);

    // console.log({ totalOffsetHeight });

    newMessageChatNodes.map((e) => {
      const computedStyle = window.getComputedStyle(e);
      const marginTop = computedStyle.getPropertyValue("margin-top");
      const marginBottom = computedStyle.getPropertyValue("margin-bottom");

      // console.log({ totalOffsetHeight });
      totalOffsetHeight =
        totalOffsetHeight +
        e.offsetHeight +
        parseFloat(marginTop) +
        parseFloat(marginBottom);
    });
    // console.log({
    //   totalOffsetHeight,
    //   scrollHeight,
    //   scrollTop,
    //   gap: totalOffsetHeight + scrollTop - scrollHeight,
    // });
    const assumedScrollHeight =
      (scrollHeight / 100) * 10 > 50
        ? 50
        : scrollHeight + (scrollHeight / 100) * 10;

    const scrolledToBottom =
      scrollTop + totalOffsetHeight > scrollHeight - assumedScrollHeight;
    setScrolledToBottom(scrolledToBottom);
  };

  const getUserFromLocalStorage = async () => {
    try {
      let accessToken = await localStorage.getItem("accessToken");
      if (!accessToken) throw new Error("No user found");

      const res = await axios.get(`${host}/user/auth`, {
        headers: { Authorization: accessToken },
      });
      console.log(res);

      if (res.status == 200) {
        setAccessToken(accessToken);
        setUser(res.data.user);
        setShowLoginModal(false);
      }

      // console.log({ user, accessToken });
    } catch (ex) {
      // alert(ex);
      console.log(ex);
    }
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    window.location.reload();
    // setUser(null);
    // setAccessToken(null);
  };

  const login = async () => {
    if (loginLoading) return;

    if (!loginForm.username) return alert("Please add a username");

    if (!loginForm.password) return alert("Please add a password");

    if (loginForm.username.trim().length < 5)
      return alert("Username must be more than 5 charecters");
    if (loginForm.password.trim().length < 5)
      return alert("Password must be more than 5 charecters");

    setLoginLoading(true);
    const res = await axios.post(
      loginUrl,
      {
        username: loginForm.username,
        password: loginForm.password,
      },
      {
        validateStatus: false,
      }
    );
    // await sleep(1000);
    console.log(res);
    if ([200, 201].includes(res.status)) {
      setUser(res.data.user);
      setAccessToken(res.data.accessToken);
      setShowLoginModal(false);

      localStorage.setItem("accessToken", res.data.accessToken);
    } else {
      console.log("shit");
      alert(res?.data?.message);
    }
    setLoginLoading(false);
  };
  const sendMessage = async () => {
    const lastMessageDate =
      messagesData &&
      messagesData.length &&
      messagesData[messagesData.length - 1]
        ? messagesData[messagesData.length - 1].date_created
        : new Date(new Date().getTime() - hoursMs * 6);

    setSendMessageLoading(true);
    const res = await axios.post(
      `${host}/chat/${selectedChatGroup._id}/`,
      {
        message: messageInput,
        lastMessageDate,
      },
      {
        headers: {
          Authorization: accessToken,
        },
        validateStatus: false,
      }
    );
    console.log(res);
    // await sleep(1000);
    if (res.status == 201) {
      console.log("message sent");
      // setMessagesData([...messagesData, ...res.data.messages]);
      // if (messagesDataByChatGroup.selectChatGroup?._id) {
      //   setMessagesDataByChatGroup({
      //     ...messagesDataByChatGroup,
      //     [selectChatGroup._id]: [
      //       ...messagesDataByChatGroup.selectChatGroup._id,
      //       ...res.data.data,
      //     ],
      //   });
      // } else {
      //   setMessagesDataByChatGroup({
      //     ...messagesDataByChatGroup,
      //     [selectChatGroup._id]: [...res.data.data],
      //   });
      // }
      setMessagesDataByChatGroup({
        ...messagesDataByChatGroup,
        [selectedChatGroup?._id]: messagesDataByChatGroup[
          selectedChatGroup?._id
        ]
          ? [
              ...messagesDataByChatGroup[selectedChatGroup._id],
              ...res.data.messages,
            ]
          : [...res.data.messages],
      });
    } else {
      alert(res?.data?.message);
    }

    setSendMessageLoading(false);
    setMessageInput("");
  };

  const getChatGroups = async () => {
    // if (getMessageLoading) return;
    if (!accessToken) return;

    setLoginLoading(true);
    const res = await axios.get(`${host}/chat`, {
      params: {},
      headers: {
        Authorization: accessToken,
      },
      validateStatus: false,
    });
    // await sleep(1000);
    console.log(res);
    if (res.status == 200) {
      setAllChatGroups([...res.data.data]);
      // console.log({ selectedChatGroup });
      if (!selectedChatGroup) {
        setSelectedChatGroup(res.data.data[0]);
      } else {
        const selectedOne = res.data.data.find(
          (cg) => cg._id == selectedChatGroup?._id
        );
        console.log({ selectedOne });
        setSelectedChatGroup(selectedOne);
      }
    } else {
      console.log("get chatgroups fail");
    }
    setLoginLoading(false);
  };

  const getMessages = async () => {
    if (!selectedChatGroup) return alert("select a chatgroup to chat");
    // console.log({ messagesData });
    const lastMessageDate = new Date(
      messagesData[messagesData.length - 1]?.date_created ?? 0
    );

    console.log({ lastMessageDate });

    // if (getMessageLoading) return;
    setGetMessageLoading(true);
    // await sleep(1000);
    // console.log("{ lastMessageDate }1");
    const res = await axios.get(`${host}/chat/${selectedChatGroup._id}/`, {
      params: {
        startTime: lastMessageDate,
        limit: 5000,
      },
      headers: {
        Authorization: accessToken,
      },
      validateStatus: false,
    });
    // const res = {};
    // console.log("{ lastMessageDate }2");
    console.log(res);
    // console.log("{ lastMessageDate }3");
    // await sleep(2000);
    if (res.status == 200) {
      // console.log("{ lastMessageDate }4");

      const obj = {
        ...messagesDataByChatGroup,
        [selectedChatGroup?._id]: messagesDataByChatGroup[
          selectedChatGroup?._id
        ]
          ? [
              ...messagesDataByChatGroup[selectedChatGroup._id],
              ...res.data.data,
            ]
          : [...res.data.data],
      };
      // const obj = {};
      // console.log({ obj });
      setMessagesDataByChatGroup(obj);
      if (!scrolledToBottom) {
        setLastFetchedMessages([...lastFetchedMessages, ...res.data.data]);
      }
    } else {
      console.log("shit");
    }
    setGetMessageLoading(false);
  };

  const selectChatGroup = (selectedChatGroupData) => {
    // setMessagesData([]);
    setSelectedChatGroup(selectedChatGroupData);
  };
  // console.log({ scrolledToBottom, lastFetchedMessages });
  // console.log({ selectedChatGroup });
  useEffect(() => {
    console.log("[scrolledToBottom, messagesData]");
    // scrollToBottom();
    if (!scrolledToBottom && lastFetchedMessages.length > 0) {
      setShowScrollToBottomButton(true);
    }
    if (scrolledToBottom) {
      setLastFetchedMessages([]);
      setShowScrollToBottomButton(false);
    }
  }, [scrolledToBottom, messagesDataByChatGroup]);

  useEffect(() => {
    console.log("[messagesData]");
    // scrollToBottom();
    if (scrolledToBottom) {
      scrollToBottom();
    }
  }, [messagesDataByChatGroup]);

  useEffect(() => {
    console.log("[accessToken]");
    if (accessToken) {
      getChatGroups();
    }
  }, [accessToken]);

  useEffect(() => {
    console.log("[accessToken, selectedChatGroup]");

    setLastFetchedMessages([]);

    if (selectedChatGroup) {
      getMessages();
      checkIfScrolledToBottom();
    }
  }, [accessToken, selectedChatGroup]);

  useEffect(() => {
    console.log("aaaa");
    getUserFromLocalStorage();
  }, [showLoginModal]);

  useEffect(() => {
    console.log("[accessToken, messagesDataByChatGroup]");
    if (accessToken && selectedChatGroup) {
      const waitingTimer = setInterval(getMessages, 3000);
      return () => {
        clearInterval(waitingTimer);
      };
    }
  }, [accessToken, messagesDataByChatGroup]);

  useEffect(() => {
    console.log("[accessToken, allChatGroups, selectedChatGroup]");
    if (accessToken) {
      let waitingTimer = setInterval(getChatGroups, 5000);
      return () => {
        clearInterval(waitingTimer);
      };
    }
  }, [accessToken, allChatGroups, selectedChatGroup]);

  useEffect(() => {
    console.log("[]");
    const waitingTimer = setTimeout(() => {
      // window.location.reload(true);
      window.location.href = window.location.href;
    }, 10 * 60 * 1000);
    return () => {
      clearTimeout(waitingTimer);
    };
  }, []);
  return (
    <div
      className={`container py-2 d-flex`}
      style={{ flexDirection: "column", maxHeight: "100vh" }}
    >
      <Modal show={showLoginModal}>
        <Modal.Header>
          <div className="d-flex justify-content-center flex1">
            <h4>Login or Register</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{}}>
            <form>
              <InputGroup className="py-2">
                <FormLabel className="flex1">username</FormLabel>
                <input
                  value={loginForm.username}
                  onChange={(e) => {
                    setLoginForm({
                      ...loginForm,
                      username: slugify(e.target.value.toLocaleLowerCase()),
                    });
                  }}
                  style={{ flex: 3 }}
                  className="ms-2 px-2 border rounded"
                  type="text"
                />
              </InputGroup>

              <InputGroup className="py-2">
                <FormLabel className="flex1">
                  password{" "}
                  <strong style={{ fontSize: "13px", fontWeight: "100" }}>
                    (number)
                  </strong>
                </FormLabel>

                <input
                  placeholder="12345678 (number)"
                  onChange={(e) => {
                    setLoginForm({ ...loginForm, password: e.target.value });
                  }}
                  style={{ flex: 3 }}
                  className="ms-2 px-2 border rounded"
                  type="number"
                />
              </InputGroup>
              <div className="">
                {loginLoading ? (
                  <Loading size="30" />
                ) : (
                  <Button onClick={login} className="btn w-100 btn-dark mt-2">
                    Login/Register
                  </Button>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <ChatGroupModal
        show={showCreateChatGroupModal}
        onClose={() => {
          setShowCreateChatGroupModal(false);
          setShowUpdateChatGroupModal(false);
        }}
        accessToken={accessToken}
        providedChatGroupData={
          showUpdateChatGroupModal ? selectedChatGroup : null
        }
        getChatGroups={getChatGroups}
        allChatGroups={allChatGroups}
      />

      <div>
        <h1 className="text-center">
          🗨️ chatter app{" "}
          <strong className="" style={{ fontSize: "1rem", fontWeight: "100" }}>
            (experimental)
          </strong>
        </h1>
        <p className="text small" style={{ textAlign: "justify" }}>
          no political and offensive messages are allowed
        </p>
        <div className="d-flex align-items-center justify-content-between-- py-1">
          <div>logged in as: {user && user.username}</div>
          <button
            className="btn border rounded border-dark p-1 px-2 ms-3"
            onClick={logout}
          >
            Logout
          </button>
        </div>
        <div className="d-flex align-items-center">
          <div>Chat Group:</div>
          <div className="flex1">
            <FormSelect
              className="mx-1 p-1 px-2"
              onChange={(e) => {
                selectChatGroup(JSON.parse(e.target.value));
              }}
            >
              {!selectedChatGroup && (
                <option selected>No Chat Group Selected</option>
              )}
              {allChatGroups.map((cg) => (
                <option
                  onChange={() => {
                    console.log(cg);
                  }}
                  value={JSON.stringify(cg)}
                  selected={selectedChatGroup?._id == cg._id}
                >
                  {cg.label}
                </option>
              ))}
            </FormSelect>
          </div>
          <div>
            <button
              className="border rounded ms-1"
              disabled={
                !selectedChatGroup?.roles
                  .find((role) => role.user == user._id)
                  ?.roles.includes("admin")
              }
              onClick={() => {
                setShowCreateChatGroupModal(true);
                setShowUpdateChatGroupModal(true);
              }}
            >
              ✏️
            </button>
            <button
              className="border rounded ms-1"
              onClick={() => setShowCreateChatGroupModal(true)}
            >
              ➕
            </button>
          </div>
        </div>
      </div>
      <ChatItemContainer onScroll={checkIfScrolledToBottom}>
        {messagesData.map(
          ({
            user: sender,
            userEmoji,
            message,
            date_created,
            _id,
            msgNumber,
          }) => (
            <ChatItem
              key={_id}
              userEmoji={userEmoji}
              username={sender?.username}
              message={message}
              date_created={date_created}
              msgNumber={msgNumber}
              sender={user._id == sender?._id}
            />
          )
        )}
      </ChatItemContainer>

      {showScrollToBottomButton && (
        <div
          style={{ position: "relative" }}
          className="pointer"
          onClick={scrollToBottom}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: "20%",
              // rotate: "180deg",
            }}
            className="p-2"
          >
            ⬇️
            <strong
              className="text text-theme"
              style={{ fontStyle: "italic", fontSize: "0.8rem" }}
            >
              ({lastFetchedMessages.length})
            </strong>
          </div>
        </div>
      )}

      {/* <MessageAction /> */}
      <div>
        {/* <form onSubmit={sendMessage}> */}
        <div style={{ display: "flex" }}>
          <textarea
            type="text"
            style={{ flex: 1 }}
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {sendMessageLoading ? (
            <Loading size="50px" />
          ) : (
            <button onClick={() => messageInput && sendMessage()}>
              Send ▶️
            </button>
          )}
        </div>
        {/* </form> */}
        <div className="d-flex pt-1 gap-1">
          {/* <button className="btn border-dark flex1">Hard Refresh</button> */}

          <div
            onClick={getMessages}
            // onClick={scroll}
            className="btn border-dark flex1 d-flex gap-2"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {getMessageLoading && <Loading size="25" />}Load New Messages
          </div>
        </div>
      </div>
    </div>
  );
}

const ChatItemContainer = ({ children, onScroll }) => {
  return (
    <div
      onScroll={onScroll}
      className="my-2 scrollBar pe-1"
      id="chatItemContainer"
      style={{ overflowY: "scroll", flex: 1 }}
    >
      {children}
    </div>
  );
};

const ChatItem = ({
  userEmoji,
  username,
  message,
  date_created,
  sender = false,
}) => {
  return (
    <div
      style={{
        display: "block",
        border: "0.25px solid black",
        borderRadius: "5px",
        maxHeight: "600px",
        overflow: "hidden",
        marginLeft: sender && "3rem",
        marginRight: !sender && "3rem",
        // backgroundColor: sender && "blue",
        // flexDirection: "revert",
      }}
      className="px-2 mb-2 bg border-dark"
    >
      <div>
        <div
          className="d-flex"
          style={{
            justifyContent: "space-between",
            // flexDirection: sender && "row-reverse",
          }}
        >
          <div className="text text-theme" style={{ fontStyle: "italic" }}>
            {username}
          </div>
          <div
            className="text text-theme"
            style={{ fontStyle: "italic", fontSize: "0.8rem" }}
          >
            {/* {date_created && new Date(String(date_created))} */}
            {readableDate(date_created)}
          </div>
        </div>
      </div>
      <div style={{ float: sender && "right" }}>{message}</div>
    </div>
  );
};

const readableDate = (time) => {
  let date = new Date(time);

  let modified_time = date.toISOString().split("T")[1];
  //   console.log(modified_time);
  modified_time = modified_time.split(":");
  modified_time = `${modified_time[0]}:${modified_time[1]}`;

  let modified_date = date.toDateString().split(" ");

  modified_date = `${modified_date[1]} ${modified_date[2].split("-")[0]}`;

  return `${modified_date} ${modified_time}`;
};
