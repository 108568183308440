import { useEffect, useState } from "react";
import {
  Modal,
  InputGroup,
  FormLabel,
  Form,
  Button,
  FormSelect,
} from "react-bootstrap";
import Loading from "../../shared/Loading";
import axios from "axios";
import { host } from "../../variables";
import { slugify } from "../../utils";
export default function ({
  show = true,
  onClose = () => {},
  accessToken,
  providedChatGroupData,
  getChatGroups,
  allChatGroups,
}) {
  // console.log("getChatGroups");
  // console.log(getChatGroups);
  // console.log("getChatGroups");
  console.log({ providedChatGroupData });

  const [searchText, setSearchText] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchUsersLoading, setSearchUsersLoading] = useState(false);
  const [createChatGroupLoading, setCreateChatGroupLoading] = useState(false);
  const [chatGroupTitle, setChatGroupTitle] = useState("");
  const [serachedUsers, setSearchedUsers] = useState([]);
  //   const [selectedChatGroupToUpdate, setSelectedChatGroupToUpdate] =
  // useState(null);
  useEffect(() => {
    if (providedChatGroupData) {
      setSelectedUsers([...providedChatGroupData.users]);
      setChatGroupTitle(providedChatGroupData.label);
    } else {
      setSelectedUsers([]);
      setChatGroupTitle("");
    }
  }, [show, providedChatGroupData]);

  const searchUsers = async () => {
    if (!accessToken) return;
    setSearchUsersLoading(true);
    const res = await axios.get(`${host}/user/`, {
      params: { username: searchText },
      headers: {
        Authorization: accessToken,
      },
      validateStatus: false,
    });
    // await sleep(1000);
    console.log(res);
    if (res.status == 200) {
      setSearchedUsers([...res.data.data]);
    } else {
      console.log("Get users fail");
    }
    setSearchUsersLoading(false);
  };

  const setChatGroup = async () => {
    if (!accessToken) return;
    setCreateChatGroupLoading(true);
    let res;
    if (providedChatGroupData) {
      res = await axios.put(
        `${host}/chat/${providedChatGroupData._id}`,
        {
          label: chatGroupTitle,
          id_name: slugify(chatGroupTitle),
          users: selectedUsers.map((e) => e._id),
        },
        {
          //   params: { username: searchText },
          headers: {
            Authorization: accessToken,
          },
          validateStatus: false,
        }
      );
    } else {
      res = await axios.post(
        `${host}/chat`,
        {
          label: chatGroupTitle,
          id_name: slugify(chatGroupTitle),
          users: selectedUsers.map((e) => e._id),
        },
        {
          //   params: { username: searchText },
          headers: {
            Authorization: accessToken,
          },
          validateStatus: false,
        }
      );
    }
    // await sleep(1000);
    console.log(res);
    if (res.status == (201 | 200)) {
      //   setSearchedUsers([...res.data.data]);
    } else {
      console.log("Create chatgrup fail");
    }
    setCreateChatGroupLoading(false);
    onClose();
    getChatGroups();
    // window.location.reload();
  };

  useEffect(() => {
    // if (!searchText || searchText == "") {
    //   return;
    // }
    let waitingTimer = setTimeout(() => searchUsers(), 500);
    return () => {
      clearTimeout(waitingTimer);
    };
  }, [searchText]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <div className="d-flex justify-content-between flex1">
          <div style={{ visibility: "hidden" }}>❌</div>
          <h4>Create Chat Group</h4>
          <div onClick={onClose}>❌</div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{}}>
          <form>
            <InputGroup className="py-2">
              <FormLabel className="flex1">Group Title</FormLabel>
              <input
                value={chatGroupTitle}
                onChange={(e) => {
                  setChatGroupTitle(
                    slugify(e.target.value.toLocaleLowerCase())
                  );
                }}
                style={{ flex: 3 }}
                className="ms-2 px-2 border rounded"
                type="text"
              />
            </InputGroup>

            <InputGroup className="py-2">
              <FormLabel className="flex1">Users 🔎</FormLabel>
              {/* <input
                value={null}
                onChange={(e) => {
                  console.log(e.target.value);
                }}
                style={{ flex: 3 }}
                className="ms-2 px-2 border rounded"
                type="text"
              /> */}
              {/* <textarea
                type="text"
                style={{ flex: 1 }}
                value={document.querySelector("#asdf")}
                onChange={(e) => console.log(e)}
              /> */}
              <div style={{ flex: 3 }}>
                <div className=" d-flex" style={{ flexWrap: "wrap" }}>
                  {selectedUsers.map((u) => (
                    <UserItem
                      username={u.username}
                      showRemoveIcon={true}
                      onClick={() => {
                        setSelectedUsers(
                          selectedUsers.filter((su) => su._id != u._id)
                        );
                      }}
                    />
                  ))}
                  <input
                    type="text"
                    style={{
                      //   border: "1px solid red",
                      //   borderColor: "white",
                      width: "80px",
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className="p-1 px-2 border rounded"
                    value={searchText}
                  />
                </div>
                <div className="scrollBar d-flex" style={{ flexWrap: "wrap" }}>
                  {serachedUsers
                    .filter(
                      (u) => !selectedUsers.map((e) => e._id).includes(u._id)
                    )
                    .map((user) => (
                      <UserItem
                        username={user.username}
                        onClick={() => {
                          setSelectedUsers([...selectedUsers, { ...user }]);
                        }}
                      />
                    ))}
                </div>
              </div>
            </InputGroup>

            <div className="" id="asdf">
              {createChatGroupLoading ? (
                <Loading size="30" />
              ) : (
                <Button
                  onClick={setChatGroup}
                  className="btn w-100 btn-dark mt-2"
                >
                  {providedChatGroupData ? "update" : "Create"}
                </Button>
              )}
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const UserItem = ({ username, onClick = () => {}, showRemoveIcon }) => {
  return (
    <div
      className="B p-1 px-2 m-1 border rounded"
      style={{ width: "fit-content", cursor: "pointer" }}
      onClick={onClick}
    >
      {username} <strong>{showRemoveIcon && "❌"}</strong>
    </div>
  );
};
