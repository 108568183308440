import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
// import store from "./store/index.ts";

// console.log({ REACT_APP_API_URL: process.env.REACT_APP_API_URL });

//@ts-ignore-next-line
const root = ReactDOM.createRoot(document.getElementById("root"));
// console.log(store.getState());
root.render(
  <StrictMode>
    {/* <Provider store={store}> */}
    <App />
    {/* </Provider> */}
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
