import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import { AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  // browserHistory,
} from "react-router-dom";
import Home from "./pages/DefaultPage";
function App() {
  // const dispatch = useDispatch();
  React.useEffect(() => {
    // dispatch(actions.init());

    const app_version = "1.1.4";

    const app_versionInLocalStorage = localStorage.getItem("app_version");
    if (app_version !== app_versionInLocalStorage) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.setItem("app_version", app_version);
      window.location.reload();
    }
  }, []);

  const [deviceType, setDeviceType] = React.useState("desktop");

  React.useEffect(() => {
    // Function to check the device type based on the window width
    const checkDeviceType = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth < 768) {
        setDeviceType("mobile");
      } else if (windowWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };
    checkDeviceType();
    window.addEventListener("resize", checkDeviceType);
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return (
    <div className="App" style={{}}>
      <ToastContainer />

      <Home />
    </div>
  );
}

export default App;
